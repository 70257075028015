<template>
  <div class="phone">
    <div class="imgDiv">
    <img src="@/assets/background2.jpg" >
    <span class="imgSpan">ABOUT US</span>
    </div>
    <span style="color:#303133">&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp深圳市达顺广告有限公司成立于2002年，公司成立20多年来一直专注于广告标识的设计、研发和生产。主要加工制作不锈钢招牌广告工程、香港工艺不锈钢字、金属烤漆电金字、盲文凸字、盲文平面地图、LED发光字、LED灯箱字、水晶字、亚克力工艺制品、精工金属字、树脂发光字、迷你字、金属腐蚀、UV打印、网板丝印、塑胶雕刻、金属激光
    <br>
    &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp自设工厂，拥有多种设备加工，欢迎新老客户来图来电询价、加工！
    </span>
    </div>
</template>

<script>
export default {
  name: "Phone",
};
</script>

<style scoped>
.phone {
  display: flex;
  height: 85vh;
  width: 90%;
  justify-content: center;
  align-items: center;
  background-color:#adb9b7;
  border-radius:20px; 
  margin-top: 10px;
  margin-bottom: 10px;
}
img{
  height:30vw;
  border-radius:20px; 
}
.imgSpan{
  position: absolute; 
  top: 38%;
  left: 40%;
  text-align: center;
  color: #EBEEF5;
  font-size: 25px;
}
.imgDiv{
  position: relative;
}
@media only screen and (max-width: 800px){
  img{height:20vh;}
  .phone{
    flex-direction: column;
  }
}
</style>