<template>
  <div class="outest">
    <div class="holder"></div>
    <Nav />
    <transition name="app" mode="out-in">
    <router-view></router-view>
    </transition>
    <Footer />
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "app",
  components: { Nav, Footer },
  data() {
    return {};
  },
  methods: {},
  watch: {
    $route(to) {
      this.$nextTick(() => {
        this.$zh_tran(localStorage.getItem("lang"));
      });
    },
  },
  created() {},
  mounted() {
    // const about = document.querySelector(".about")
    // console.log(about.offsetTop)
    if (!localStorage.getItem("lang")) {
      //去本地存储找lang 如果没有就补上
      localStorage.setItem("lang", "t");
    } //默认值为繁体
    else {
      this.$zh_tran(localStorage.getItem("lang")); //有就设置一遍
    }
  },
};
</script>
<style scoped>
.outest {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.holder {
  height: 12vh;
}

.app-enter-active, .app-leave-active {
  transition-duration:0.5s;
}
.app-enter, .app-leave-to {
  opacity: 0;
  transition-duration:0.5s;
}
</style>>
