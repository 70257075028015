<template>
    <div class="detail">
      <div v-for="(value, index) in imgSrc"  class="imgDiv">
        <el-image
          :src="value"
          class="img"
          :preview-src-list="imgSrc"
          lazy
          :key="index"
        ></el-image>
        <div class="imgName">
          <span>{{ imgName[index] }}</span></div>
      </div>
    
    </div>
</template>

<script>
export default {
  name: "Detail",
  data() {
    return {
      imgSrc: [],
      imgName: [],
    };
  },
  computed: {
    to() {
      return this.$route.query.to;
    },
  },
  methods: {
    getImg() {
      switch (this.to) {
        case "灯箱产品":
          this.imgSrc = [];
          this.imgName = [];
          require
            .context("../../public/灯箱产品", false, /\.(png|jpg|jpeg)$/)
            .keys()
            .forEach((key) => {
              key = key.slice(1);
              this.imgSrc.push(require("../../public/灯箱产品" + key));
              key = key.substring(1, key.indexOf("."));
              if (!isNaN(parseFloat(key)) && isFinite(key)) {
                this.imgName.push("");
              } else {
                this.imgName.push(key);
              }
            });
          return;
        case "雕刻激光":
          this.imgSrc = [];
          this.imgName = [];
          require
            .context("../../public/雕刻激光", false, /\.(png|jpg|jpeg)$/)
            .keys()
            .forEach((key) => {
              key = key.slice(1);
              this.imgSrc.push(require("../../public/雕刻激光" + key));
              key = key.substring(1, key.indexOf("."));
              if (!isNaN(parseFloat(key)) && isFinite(key)) {
                this.imgName.push("");
              } else {
                this.imgName.push(key);
              }
            });
          return;
        case "精工艺字":
          this.imgSrc = [];
          this.imgName = [];
          require
            .context("../../public/精工艺字", false, /\.(png|jpg|jpeg)$/)
            .keys()
            .forEach((key) => {
              key = key.slice(1);
              this.imgSrc.push(require("../../public/精工艺字" + key));
              key = key.substring(1, key.indexOf("."));
              if (!isNaN(parseFloat(key)) && isFinite(key)) {
                this.imgName.push("");
              } else {
                this.imgName.push(key);
              }
            });
          return;
        case "盲文凸字":
          this.imgSrc = [];
          this.imgName = [];
          require
            .context("../../public/盲文凸字", false, /\.(png|jpg|jpeg)$/)
            .keys()
            .forEach((key) => {
              key = key.slice(1);
              this.imgSrc.push(require("../../public/盲文凸字" + key));
              key = key.substring(1, key.indexOf("."));
              if (!isNaN(parseFloat(key)) && isFinite(key)) {
                this.imgName.push("");
              } else {
                this.imgName.push(key);
              }
            });
          return;
        case "喷绘打印":
          this.imgSrc = [];
          this.imgName = [];
          require
            .context("../../public/喷绘打印", false, /\.(png|jpg|jpeg)$/)
            .keys()
            .forEach((key) => {
              key = key.slice(1);
              this.imgSrc.push(require("../../public/喷绘打印" + key));
              key = key.substring(1, key.indexOf("."));
              if (!isNaN(parseFloat(key)) && isFinite(key)) {
                this.imgName.push("");
              } else {
                this.imgName.push(key);
              }
            });
          return;
        case "指示标牌":
          this.imgSrc = [];
          this.imgName = [];
          require
            .context("../../public/指示标牌", false, /\.(png|jpg|jpeg)$/)
            .keys()
            .forEach((key) => {
              key = key.slice(1);
              this.imgSrc.push(require("../../public/指示标牌" + key));
              key = key.substring(1, key.indexOf("."));
              if (!isNaN(parseFloat(key)) && isFinite(key)) {
                this.imgName.push("");
              } else {
                this.imgName.push(key);
              }
            });
          return;
      }
    },
  },
  watch:{
    to:{
      handler(){
        this.getImg()
      },
      immediate: true
    }
  }
};
</script>

<style scoped>
.detail {
  width: 99%;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  margin-left: 2%;
}
.img {
  height: 15vw;
  width: 22vw;
  border-radius: 10%;
  margin: 15px;
  transition-duration: 1s;
  filter: brightness(80%);
  z-index: 5;
}
.img:hover {
  filter: brightness(100%);
}
.imgDiv:hover {
  transform: scale(1.03);
  transition-duration: 1s;
}
.imgDiv {
  height: 100%;
  position: relative;
  transition-duration: 1s;
}
.imgName {
  position: absolute;
  display: flex;
  width: 22vw;
  height: 15vw;
  line-height: 3vh;
  bottom: 20px;
  left: 15px;
  color: rgba(255, 255, 255, 0.762);
  font-size: 14px;
  text-align: center;
  transition-duration: 1s;
  justify-content: center;
  align-items: flex-end;
}
span{
  z-index: 6;
  transition-duration: 1s;
}
@media only screen and (max-width: 700px) {
  .img {
    height: 30vw;
    width: 40vw;
    margin: 7px;
  }
  .imgName {
    width: 40vw;
    border-radius: 5px 5px 20px 20px;
  }
  .detail{
    margin-left: 7%;
  }
}
span:hover {
  color: #f5a623;
  font-weight: bolder;
  transition-duration: 1s;
  transform:scale(1.4);
}
</style>