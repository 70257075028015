<template>
  <div class="more" @click="onclick" :style="setColor" :class="divClass" >
    <img :src="require('../assets/svg/' + svgSrc)" :class="imgClass" v-if="svgSrc">
    <span class="title" :class="spanClass">{{ to }}</span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "More",
  data() {
    return {
      imgClass: "",
      divClass: "",
      spanClass: "",
    };
  },
  props: {
    svgSrc: {
      type: String,
      required: false,
    },
    textColor: {
      type: String,
      required: false,
    },
    to: {
      type: String,
      required: false,
    },
    height:{
      type: String,
      required: false,
      default: "40vh"
    }
  },
  methods: {
    onclick() {
      this.$router
        .push({
          name: "Detail",
          query: {
            to: this.to,
          },
        })
        .catch((err) => {console.log(err)
        });
    },
  },
  computed: {
    setColor() {
      return { "--color": this.textColor };
    },
    setHeight(){
      return {  "--height":this.height}
    },
    path() {
      return this.$route.query.to;
    },
  },
  watch: {
    path: {
      handler(oldValue, newValue) {
        if (oldValue === this.to) {
          this.imgClass = "imgClass";
          this.divClass = "divClass";
          this.spanClass = "spanClass";
        } else {
          this.imgClass = "";
          this.divClass = "";
          this.spanClass = "";
        }
      },
      immediate: true,
    },
  },
  mounted() {},
};
</script>
<style scoped>
.more {
  margin: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 30%;
  height: var(--height);
  border-radius: 50px;
  transition-duration: 1s;
}
.more:hover span {
  background-color: var(--color);
  color: white;
  transition-duration: 1s;
}
.more:hover img {
  background-color: white;
  border-radius: 50%;
  transition-duration: 1s;
}
.more:hover {
  background-color: var(--color);
  transition-duration: 1s;
}
span {
  font-size: 15px;
  margin-top: 8px;
  border-radius: 10%;
  transition-duration: 1s;
}
.title {
  font-size: 25px;
  color: var(--color);
  transition-duration: 1s;
}
img {
  transition-duration: 1s;
}
@media only screen and (max-width: 550px) {
  span {
    font-size: 13px;
  }
  .title {
    font-size: 14px;
    font-weight: bold;
  }
  .more {
    border-radius: 40px;
  }
}
.imgClass {
  background-color: white;
  border-radius: 50%;
  transition-duration: 1s;
}
.divClass {
  background-color: var(--color);
  transition-duration: 1s;
}
.spanClass {
  background-color: var(--color);
  color: white;
  transition-duration: 1s;
}
</style>