import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../pages/Home.vue'
import Product from '../pages/Product.vue'
import Phone from '../pages/Phone.vue'
import Detail from '../components/Detail.vue'
import Test from '../components/Test.vue'

Vue.use(VueRouter)
export default new VueRouter({
  mode:'history',
  routes:[{
    name: 'Home',
    path: '/',
    component:Home,
  },
  {
    name: 'Product',
    path: '/product',
    component:Product,
    children:[
      {
        name: 'Detail',
        path: 'detail',
        component:Detail,
      }
    ],
  },
  {
    name:'Phone',
    path:'/phone',
    component:Phone,
  },
  {
    name:'Test',
    path:'/test',
    component:Test,
  }
]})