<template>
  <div class="footer">
    <div class="detail">  
      <img src="@/assets/svg/qq.svg" />
      <span>：363832561</span>
      <br /> 
      <img src="@/assets/svg/web.svg" /><span>：www.dashun.vip</span>
      <br />
      <img src="@/assets/svg/phone.svg" />
      <img src="@/assets/svg/weixin.svg" />
      <span>：13530432892 <br/>
      <img src="@/assets/svg/phone.svg" />
      <img src="@/assets/svg/weixin.svg" />：13686408825</span>
      <br />
      <img src="@/assets/svg/email.svg" />
      <span>：sz25610990@163.com</span>
      <br />
      <span>地址：深圳市龙岗区六约牛始铺三区12号一楼</span>
    </div>
    <img src="@/assets/add.jpg" class="imgClass" />
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.footer {
  height: 25vh;
  width: 100%;
  display: flex;
  background-color: black;
  margin-top: auto;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
}
.detail {
  color: #909399;
  margin-right: 10vw;
}
.imgClass {
  height: 60%;
}


</style>