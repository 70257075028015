<template>
  <div class="home">
      <Carousel/>
    <div class="More">
      <More :svgSrc="svgSrc[1]" textColor="#F56C6C" to="精工艺字">
        <span>金属烤漆字</span> <span>LED发光字</span> <span>不锈钢工艺字</span
        ><span>电镀工艺制品 </span></More
      >
      <More :svgSrc="svgSrc[2]" textColor="#C572EC" to="灯箱产品">
        <span>超薄灯箱</span><span>LED灯箱</span> <span>金属单双面灯箱</span
        ><span>室内外发光灯箱 </span>
      </More>
      <More :svgSrc="svgSrc[0]" textColor="#4a90e2" to="指示标牌">
        <span>电镀标牌</span><span>亚克力标牌</span> <span>UV打印标牌</span
        ><span>金属腐蚀标牌 </span></More
      >
      <More :svgSrc="svgSrc[3]" textColor="#f5a623" to="雕刻激光">
        <span>金属激光</span> <span>塑胶雕刻</span> <span>亚克力工艺品</span>
      </More>
      <More :svgSrc="svgSrc[4]" textColor="#000099" to="盲文凸字">
        <span>盲文平面地图</span><span>盲文指示标牌</span>
        <span>公共设施导图</span></More
      >
      <More :svgSrc="svgSrc[5]" textColor="#67C23A" to="喷绘打印">
        <span>高清喷绘</span><span>网板丝印</span> <span>割字UV打印</span></More
      >
    </div>
    <Phone></Phone>
  </div>
</template>

<script>
import More from "@/components/More.vue";
import Phone from "@/pages/Phone.vue";
import Carousel from "@/components/Carousel.vue";
export default {
  name: "Home",
  components: { More ,Phone,Carousel},
  data() {
    return {
      svgSrc: [
        "sign.svg",
        "font.svg",
        "light.svg",
        "lightning.svg",
        "drag.svg",
        "print.svg",
      ],
    };
  },
  methods: {
    toPhone() {
      this.$router.push("/phone");
    },
  }
};
</script>

<style scoped>
.home {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 90%;
  margin: 1%;
}
.More {
  margin-top: 2%;
  height: 85vh;
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-around;
}
</style>
