<template>
  <div class="product">
      <router-view></router-view>
  </div>
</template>

<script>
import More from "@/components/More.vue";
export default {
  name: "Product",
  components: { More },
  methods: {
  },
  computed: {
    show() {
      return this.$route.path.includes("detail");
    },
  },
  data() {
    return {
      svgSrc: [
        "sign.svg",
        "font.svg",
        "light.svg",
        "lightning.svg",
        "drag.svg",
        "print.svg",
      ],
    };
  },
};
</script>

<style scoped>
.product {
  display: flex;
  width: 100vw;
}

</style>