<template>
<div class="Nav">
  <div class="nav" :class="openClass">
    <img :src="imgSrc" 
    style="width:150px;max-width:25vw;height:40px" >
      <el-menu 
      :default-active="defaultActive" 
       class="el-menu-demo menu" mode="horizontal" 
      :background-color=menuColor 
      :text-color=textColor
      :active-text-color=activeColor
      :router='true'
      >
      <el-menu-item index="/">首页</el-menu-item>
      <el-menu-item index="/product">产品</el-menu-item>
      <el-menu-item index="/phone">我们</a></el-menu-item>
       </el-menu>
       <div>
        <i class="el-icon-refresh"
         @click="changeLanguage(simple)"
        style="cursor:pointer;"
         ></i>
        <span v-show="simple" > 简</span>
        <span v-show="!simple" > 繁</span>
        </div>
       
  </div>
  <div class="More">
      <More  textColor="#F56C6C" to="精工艺字" height="9vh"> </More>
      <More  textColor="#C572EC" to="灯箱产品" height="9vh"> </More>
      <More  textColor="#4a90e2" to="指示标牌" height="9vh"> </More>
      <More  textColor="#f5a623" to="雕刻激光" height="9vh"> </More>
      <More  textColor="#000099" to="盲文凸字" height="9vh"> </More>
      <More  textColor="#67C23A" to="喷绘打印" height="9vh"> </More>
  </div>
  </div>
</template>

<script>
import More from "@/components/More.vue";
export default {
  name: "Nav",
  components:{More},
  data() {
    return {
      openClass: "",
      menuColor: "",
      textColor: "",
      activeColor: "",
      simple: true,
      imgSrc: require("../assets/logo.png"), //动态拼接图片src
    };
  },
  computed: {
    defaultActive(){
      if(this.$route.path.includes("product")){return "/product"}
      else{return this.$route.path}
    }
  },
  methods: {
    changeLanguage(s) {
      //!s表示现在所在的语言
      this.simple = !this.simple;
      if (!s) {
        //表示为简体
        localStorage.setItem("lang", "s");
      } else {
        //表示为繁体
        localStorage.setItem("lang", "t");
      }
      this.$zh_tran(localStorage.getItem("lang"));
    },
  },
  mounted() {
    let nav = document.querySelector(".holder"); //使用内置IntersectionObserver，减少性能消耗
    let animation = new IntersectionObserver((e) => {
      if (!e[0].isIntersecting) {
        this.openClass = "open"; //下滑展开
        this.menuColor = "black";
        this.textColor = "#DCDFE6";
        this.activeColor = "#409EFF";
        this.imgSrc = require("../assets/logoChange.png");
      } else {
        this.openClass = ""; //上划关闭
        this.menuColor = "";
        this.textColor = "";
        this.activeColor = "";
        this.imgSrc = require("../assets/logo.png");
      }
    });
    animation.observe(nav);
    // window.addEventListener("scroll", () => {       //监听scroll事件，更改css
    //     if (window.scrollY > (window.innerHeight)*0.3 ) {
    //       this.openClass = "open"; //下滑展开
    //       this.menuColor = "black";
    //       this.textColor = "#DCDFE6";
    //       this.activeColor = "#409EFF";
    //       this.imgSrc=require("../assets/logoChange.svg")
    //     } else {
    //       this.openClass = ""; //上划关闭
    //       this.menuColor = "";
    //       this.textColor = "";
    //       this.activeColor = "";
    //       this.imgSrc=require("../assets/logo.svg")
    //     }
    //   }
    // );
    if (localStorage.getItem("lang") === "t") {
      //通过本地存储去改变一下组件data值，解决刷新bug
      this.simple = false;
    }
  },
  updated() {},
};
</script>
<style scoped>
.nav {
  display: flex;
  overflow: auto;
  top: 0;
  left: 0;
  position: fixed;
  background-color: white;
  width: 100vw;
  height: 11vh;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition-duration: 0.3s;
  z-index: 10;
}
.open {
  background-color: black;
  color: #c0c4cc;
  transition-duration: 0s;
}
.menu {
  height:60px;
  margin-left:-20vw
}
@media only screen and (max-width: 800px){
.menu {
  margin-left:0px
}
}
.Nav{
  display:flex;
}
.More {
  display: flex;
  height: 11vh;
  width: 100vw;
}
</style>
