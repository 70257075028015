<template>
  <div class="carousel">
    <button class="button-left" @click="minusIndex"><</button>
    <div class="imgDiv">
      <div v-for="(value, index) in carousel.imgSrc" >
        <transition :name="animation" mode="out-in">
          <img class="img" :src="value.src" v-show="carousel.showIndex === index + 1" :key="value.id"/>
        </transition>
      </div>
    </div>
    <div class="bigBall">
    <button v-for="(value, index) in carousel.imgSrc" 
    class="ball" 
    :key="value.id"
     @mouseenter ="changeIndex(index)" 
     @mouseleave="setInterval()">
        <transition :name="animation" mode="out-in">
        <img src="@/assets/svg/round.svg" v-show="carousel.showIndex === index + 1"></img>
        </transition>
    </button>
    </div>
    <button class="button-right" @click="addIndex">></button>
  </div>
</template>

<script>
export default {
  name: "Carousel",
  data() {
    return {
      carousel: {
        imgSrc: [
          { src: require("../../public/轮播图/1.jpg"), id: 1 },
          { src: require("../../public/轮播图/2.png"), id: 2 },
          { src: require("../../public/轮播图/3.png"), id: 3 },
          { src: require("../../public/轮播图/4.png"), id: 4 },
          { src: require("../../public/轮播图/5.jpg"), id: 5 },
          { src: require("../../public/轮播图/6.jpg"), id: 6 },
        ],
        showIndex: 1,
      },
      animation: "come",
    };
  },
  methods: {
    addIndex() {
      if (this.carousel.showIndex < this.maxIndex) {
        this.carousel.showIndex += 1;
      } else {
        this.carousel.showIndex = 1;
      }
    },
    minusIndex() {
      if (this.carousel.showIndex > 1) {
        this.carousel.showIndex -= 1;
      } else {
        this.carousel.showIndex = this.maxIndex;
      }
    },
    changeIndex(index) {
      window.clearInterval(this.timer); //鼠标移入就清除
      this.carousel.showIndex = index + 1;
    },
    setInterval() {
      this.timer = setInterval(() => {
        this.addIndex(); //轮播图4秒加一次
      }, 4000);
    },
  },
  computed: {
    maxIndex() {
      return this.carousel.imgSrc.length;
    },
  },
  mounted() {
    this.setInterval();
  },
  destroyed() {
    window.clearInterval(this.timer); //离开当前组件就清除掉
  },
};
</script>

<style scoped>
.carousel {
  position: relative;
  height: 70vh;
  width: 95vw;
  align-items: center;
}
.img {
  height: 70vh;
  width: 95vw;
  z-index: 2;
  border-radius: 20px;
}
.imgDiv {
  display: flex;
  flex-direction: row;
}
.button-left {
  position: absolute;
  z-index: 3;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  top: 50%;
  left: 0;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  border: 0;
  font-size: 25px;
  transition: 1s;
}
.button-right {
  position: absolute;
  z-index: 3;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  top: 50%;
  right: 0;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  border: 0;
  font-size: 25px;
  transition: 1s;
}
.button-right:hover,
.button-left:hover {
  background-color: rgb(0, 0, 0, 0.8);
  transition: 1s;
  color: white;
}
.bigBall {
  position: absolute;
  bottom: 1%;
  left: 5%;
  z-index: 3;
  display: flex;
}
.ball {
  border-radius: 50%;
  margin-right: 2px;
  height: 20px;
  width: 20px;
  background-color: rgba(255, 255, 255, 0.3);
  border: 0;
  transition: 1s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ball:hover {
  background-color: #409eff;
}
.come-enter {
  opacity: 0;
}
.come-enter-active,
.come-leave-active {
  transition: 1s;
  position: absolute;
}
.come-leave {
  opacity: 1;
}
.come-enter-to {
  opacity: 1;
}
.come-leave-to {
  opacity: 0;
  position: absolute;
}
</style>